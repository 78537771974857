import React from "react";
import { AiOutlineHome } from "react-icons/ai";
import { Link } from "react-router-dom";

// import logo and img
import LogoNovwell from "../../assets/svg/logo.svg";
import ScrollAnimation from "./animations/ScrollAnimation";

const Logo = () => {
  return (
    <section className="bg-background">
      {/* div logo */}
      <ScrollAnimation direction="top" delay={1.3}>
        <div className=" flex items-center space-x-7">
          <Link to="/">
            <img
              className="py-9 pl-10"
              width={159}
              height={60}
              src={LogoNovwell}
              alt="logo novwell"
            />
          </Link>
          <div className="flex items-center space-x-3 bg-white text-lg text-bluetextcoming px-7 py-2 rounded-xl font-semibold">
            <AiOutlineHome className="text-xl" />
            <Link to="/">Home</Link>
          </div>
        </div>
      </ScrollAnimation>
    </section>
  );
};

export default Logo;

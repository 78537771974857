import { Route, Routes, useLocation } from "react-router-dom";
import Logo from "./shared/components/Logo";
import { lazy, Suspense, useEffect } from "react";
import Layout from "./shared/layout/Layout";
import NotFound from "./pages/NotFound";

const Home = lazy(() => import("./pages/Home")); // Composant différé pour la page d'accueil
const Contact = lazy(() => import("./pages/Contact")); // Composant différé pour la page de contact
const DesignFirst = lazy(() => import("./pages/DesignFirst")); // Composant différé pour la page DesignFirst
const WorkBetter = lazy(() => import("./pages/WorkBetter")); // Composant différé pour la page WorkBetter
const StartQuickly = lazy(() => import("./pages/StartQuickly")); // Composant différé pour la page StartQuickly
const About = lazy(() => import("./pages/About")); // Composant différé pour la page About
const CookieConsentBanner = lazy(() =>
  import("./shared/components/CookieConsentBanner")
); // Composant différé pour la bannière de consentement aux cookies
const PolitiqueConfidentiality = lazy(() =>
  import("./pages/PolitiqueConfidentiality")
); // Composant différé pour la page PolitiqueConfidentiality

function App() {
  const location = useLocation();

  // Utilisation de useEffect pour gérer le défilement vers le haut de la page lors d'un changement de route
  useEffect(() => {
    window.scrollTo(0, 0); // Défilement vers le haut de la page
  }, [location.pathname]);

  return (
    <div>
      {/* Affichage du logo seulement lorsque la route n'est pas la racine */}
      {location.pathname !== "/" && !location.pathname.includes("/*") && (
        <div className="block">
          <Logo />
        </div>
      )}
      <Suspense>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route path="/" element={<Home />} />
            {/* Route pour la page d'accueil */}
            <Route path="/contactez-nous" element={<Contact />} />
            {/* Route pour la page de contact */}
            <Route
              path="/politique-de-confidentialite"
              element={<PolitiqueConfidentiality />}
            />{" "}
            {/* Route pour la page PolitiqueConfidentiality */}
            <Route path="/design-first" element={<DesignFirst />} />
            {/* Route pour la page DesignFirst */}
            <Route path="/start-quickly" element={<StartQuickly />} />
            {/* Route pour la page StartQuickly */}
            <Route path="/work-better" element={<WorkBetter />} />
            <Route path="/about" element={<About />} />
            {/* Route pour la page About */}
          </Route>
          <Route path="/*" element={<NotFound />} />
        </Routes>
        <CookieConsentBanner />

        {/* Affichage de la bannière de consentement aux cookies */}
      </Suspense>
    </div>
  );
}

export default App;

import React from "react";
import { motion } from "framer-motion";

const LetterAnimation = ({ text, delay, className = "" }) => {
  const letters = text.split("");

  const letterVariants = {
    initial: {
      opacity: 0,
      y: 20,
    },
    animate: {
      opacity: 1,
      y: 0,
    },
  };

  return (
    <h1 className={` mx-auto ${className}`}>
      {letters.map((letter, index) => (
        <motion.span
          key={index}
          variants={letterVariants}
          initial="initial"
          animate="animate"
          transition={{ delay: index * delay }}
        >
          {letter}
        </motion.span>
      ))}
    </h1>
  );
};

export default LetterAnimation;

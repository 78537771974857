import { motion } from "framer-motion";

const TransitionPage = () => {
  const transitionDuration = 0.9;
  const transitionDelay = 0.2;

  return (
    <>
      <motion.div
        className="fixed top-0 bottom-0 z-30 w-screen h-screen right-full bg-gradient"
        initial={{ x: "100%", width: "100%" }}
        exit={{ x: ["0%", "100%"], width: ["0%", "100%"] }}
        animate={{ x: "0%", width: "O%" }}
        transition={{ duration: transitionDuration, ease: "easeInOut" }}
      />
      <motion.div
        className="fixed top-0 bottom-0 right-full w-screen h-screen z-20 bg-[#B382E8]"
        initial={{ x: "100%", width: "100%" }}
        animate={{ x: "0%", width: "O%" }}
        transition={{
          delay: transitionDelay,
          duration: transitionDuration,
          ease: "easeInOut",
        }}
      />
      <motion.div
        className="fixed top-0 bottom-0 z-10 w-screen h-screen right-full bg-bluecoming"
        initial={{ x: "100%", width: "100%" }}
        animate={{ x: "0%", width: "O%" }}
        transition={{
          delay: transitionDelay * 2,
          duration: transitionDuration,
          ease: "easeInOut",
        }}
      />
    </>
  );
};

export default TransitionPage;

import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";

const ScrollAnimation = ({
  children,
  direction = "bottom",
  delay = 0,
  className = "",
}) => {
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.2,
  });

  // Définir les variantes d'animation en fonction de la direction choisie
  const animationVariants = {
    hidden: {
      opacity: 0,
      x:
        direction === "left"
          ? 30 // Si la direction est "left", le composant glisse depuis la gauche
          : direction === "right"
          ? -30 // Si la direction est "right", le composant glisse depuis la droite
          : 0,
      y:
        direction === "top"
          ? 30 // Si la direction est "top", le composant glisse vers le haut
          : direction === "bottom"
          ? -30 // Si la direction est "bottom", le composant glisse vers le bas
          : 0,
    },
    visible: { opacity: 1, x: 0, y: 0 },
  };

  return (
    <motion.div
      ref={ref}
      initial="hidden"
      animate={inView ? "visible" : "hidden"}
      variants={animationVariants}
      transition={{ duration: 0.2, delay }}
      className={className}
    >
      {children}
    </motion.div>
  );
};

export default ScrollAnimation;

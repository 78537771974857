const Button = ({ className = "", onClick, label }) => {
  return (
    <div
      className={`bg-white text-bluecoming py-5  text-center mx-auto text-xl font-semibold rounded-[64px] ${className}`}
    >
      <button className={`${className}`} onClick={onClick}>
        {label}
      </button>
    </div>
  );
};

export default Button;

import React from "react";
import { Link } from "react-router-dom";
// import Img
import Error from "../assets/png/error.png";
import LetterAnimation from "../shared/components/animations/LetterAnimation";
import ScrollAnimation from "../shared/components/animations/ScrollAnimation";
import TransitionPage from "../shared/components/animations/TransitionPage";
import Button from "../shared/components/Button";

const Notfound = () => {
  return (
    <section className="bg-[#050B25] h-screen">
      <TransitionPage />
      {/* div main */}
      <div className="hidden lg:block">
        {/* div title */}
        <div
          className="text-center pt-32 h-[450px] lg:text-[98px]"
          style={{
            backgroundImage: `url(${Error})`,
            backgroundSize: "972px",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
          }}
        >
          <LetterAnimation
            delay={0.6}
            className="mx-auto text-[200px] mt-28"
            text="404"
          />
          <ScrollAnimation delay={0.6}>
            <h2 className="text-white pt-9">
              La page que vous recherchez n’existe pas
            </h2>
          </ScrollAnimation>
        </div>
        {/* div link button */}
        <div className="pt-20 max-w-[400px] mx-auto">
          <ScrollAnimation delay={0.6}>
            <Link to="/">
              <Button
                className="text-white bg-gradient"
                label="Retourner sur la page d’accueil"
              />
            </Link>
          </ScrollAnimation>
        </div>
      </div>
      {/* div main */}
      <div className="mx-8 lg:hidden">
        {/* div title */}
        <div className="text-center pt-[72px] lg:text-[98px]">
          <LetterAnimation delay={0.6} className="mx-auto" text="404" />
        </div>
        {/* div link button */}
        <div className="pt-10 max-w-[400px] mx-auto">
          <ScrollAnimation delay={0.6}>
            <Link to="/">
              <Button
                className="text-white bg-gradient"
                label="Retourner sur la page d’accueil"
              />
            </Link>
          </ScrollAnimation>
        </div>
      </div>
    </section>
  );
};

export default Notfound;
